import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { login } from './login.model'
import { MainService } from '../main.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    login: login;
    employee: string;
    phone: string;
    userID: string;
    tDAte: Date;
    email: string;
    designation: string;
    iserror: Boolean = false;
    loading: string = 'Login';
    error: string;
    disable: Boolean = false;
    apiurl: String;
    representation_id:number 
    constructor(
        private _formBuilder: FormBuilder,
        private _httpClient: HttpClient,
        private router: Router,
        private mainserveice: MainService
    ) {
        this.apiurl = mainserveice.api_url;
    }

    ngOnInit(): void {

        this.mainserveice.checklogin();
        var data = { message: 'hari' }


        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
        //if  (JSON.parse(localStorage.getItem('userID')) != undefined) {
        //    this.router.navigate(['/representation'])
        //}
    }
    errorReset() {
        this.iserror = false;
    }

    onSubmit(form: NgForm) {
        //console.log('Your form data : ', form.value);
        this.loading = 'Loading';
        const userName = form.value.email;
        const password = form.value.password;
        if (userName == '') {
            this.iserror = true;
            this.error = 'Please Provide Username';
        } else if (password == '') {
            this.iserror = true;
            this.error = 'Please Provide password';
        }
        else if (password == null) {
            this.iserror = true;
            this.error = 'Please Provide password';
        }
        else if (userName == null) {
            this.iserror = true;
            this.error = 'Please Provide Username';
        }
        else {

            console.log(userName, 'password')
            this.disable = true;
            this.iserror = false;
            console.log(this.apiurl + '/web/auth/adminLogin/' + userName + '/' + password)
            this._httpClient.get<login[]>(`${this.apiurl}/web/auth/adminLogin/${userName}/${password}`)
                .subscribe((response: any) => {

                    console.log(response);

                    //console.log(userName, password)
                    if (response.success == true) {

                        this.mainserveice.ticker('Login Successfully');
                        this.login = response['data'];
                        console.log(this.login[0]);
                        this.tDAte = new Date(response['date']);
                        //this.tDAte = new Date(response.data.)
                        //console.log(this.tDAte);
                        //console.log(this.login[0].PROPERTY_DESCRIPTION)
                        this.employee = this.login[0]['user_name']
                        this.phone = this.login[0]['user_phone']
                        this.userID = this.login[0]['id']
                        this.email = this.login[0]['user_email']
                        this.designation = this.login[0]['designation']
                        this.tDAte = this.login[0]['date']
                        this.representation_id = this.login[0]['state']
                        console.log(this.representation_id)
                        localStorage.setItem('employees', JSON.stringify(this.employee))
                        localStorage.setItem('phone', JSON.stringify(this.phone))
                        localStorage.setItem('userID', JSON.stringify(this.userID))
                        localStorage.setItem('email', JSON.stringify(this.email))
                        localStorage.setItem('designation', JSON.stringify(this.designation))
                        localStorage.setItem('tdate', JSON.stringify(this.tDAte))
                        localStorage.setItem('rep_id', JSON.stringify(this.representation_id))

                        this.router.navigate(['/subscriber'])

                        //this.router.navigate(['/representation'])

                    } else {
                        localStorage.removeItem('loggedIn')
                        this.loading = 'Login Again';
                        this.iserror = true;
                        this.error = 'Wrong Credentials';
                        form.reset({ email: '', password: '' })
                        this.disable = false;
                    }
                })
        }



    }
}